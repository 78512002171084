import Steps from "antd/lib/steps";
import React, { useCallback } from "react";
import styled from "styled-components";
import { Line } from "./items/Line";
import { RoadmapItem } from "views/NFTMarket/types";
import { WrapCustomBorder } from "components/WrapContent/WrapCustomBorder";
import Image from "next/image";
const ActiveIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="12"
      height="37"
      viewBox="0 0 12 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99963 1.49219L5.99963 0.492188L4.99963 1.49219L0.342773 6.14904L4.99963 10.8059L4.99963 36.4922H6.99963L6.99963 10.8059L11.6565 6.14904L6.99963 1.49219Z"
        fill={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1021_3057"
          x1="3.69137"
          y1="3.58594"
          x2="3.69137"
          y2="34.9922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AF862C" />
          <stop offset="0.425373" stopColor="#F8F29C" />
          <stop offset="0.665625" stopColor="#D9BD5C" />
          <stop offset="1" stopColor="#AD842A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
ActiveIcon.displayName = "ActiveIcon";
const Frame = ({ color }: { color?: string }) => {
  return (
    <svg
      width="496"
      height="150"
      viewBox="0 0 496 150"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.49219L2 2.49219L2 8.49219L4 8.49219L6 8.49219L8 8.49219L8 6.49219L8 4.49219L8 2.49219ZM10 10.4922L10 8.49219L486 8.49227L486 10.4923L488 10.4923L488 139.492L486 139.492L486 141.492L9.99998 141.492L9.99998 139.492L7.99998 139.492L8 10.4922L10 10.4922ZM9.99997 143.492L9.99997 145.492L486 145.492L486 143.492L9.99997 143.492ZM488 147.492L494 147.492L494 141.492L492 141.492L490 141.492L488 141.492L488 143.492L488 145.492L488 147.492ZM486 147.492L9.99997 147.492L9.99997 149.492L7.99997 149.492L1.99997 149.492L-2.6052e-05 149.492L-2.57023e-05 147.492L-2.46532e-05 141.492L-2.43035e-05 139.492L1.99998 139.492L2 10.4922L-1.74846e-06 10.4922L-1.39876e-06 8.49219L-3.49691e-07 2.49219L0 0.492188L2 0.492188L8 0.492189L10 0.492189L10 2.49219L486 2.49227L486 0.492272L488 0.492273L494 0.492274L496 0.492274L496 2.49227L496 8.49227L496 10.4923L494 10.4923L494 139.492L496 139.492L496 141.492L496 147.492L496 149.492L494 149.492L488 149.492L486 149.492L486 147.492ZM7.99997 147.492L7.99997 145.492L7.99997 143.492L7.99998 141.492L5.99998 141.492L3.99998 141.492L1.99998 141.492L1.99997 147.492L7.99997 147.492ZM486 4.49227L486 6.49227L10 6.49219L10 4.49219L486 4.49227ZM490 8.49227L488 8.49227L488 6.49227L488 4.49227L488 2.49227L494 2.49227L494 8.49227L492 8.49227L490 8.49227ZM3.99998 139.492L4 10.4922L6 10.4922L5.99998 139.492L3.99998 139.492ZM492 139.492L492 10.4923L490 10.4923L490 139.492L492 139.492Z"
        fill={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1021_3063"
          x1="146.805"
          y1="13.2969"
          x2="146.805"
          y2="143.284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AF862C" />
          <stop offset="0.425373" stopColor="#F8F29C" />
          <stop offset="0.665625" stopColor="#D9BD5C" />
          <stop offset="1" stopColor="#AD842A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
Frame.displayName = "Frame";

const customDot = (dot: any, data: any, roadmaps: any) => {
  const { index, title, description } = data;
  const isActive = index <= 2;
  const activeColor = index > 2 ? "#31323C" : "#AF862C";
  const isLastItem = index === roadmaps.length - 1;
  const Wrap = styled.div<{
    isActive: boolean;
  }>`
    background: ${({ isActive }) => {
      return isActive
        ? "linear-gradient( 0deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0.88) 100% ), linear-gradient(180deg, #794c03 0%, #ffeac7 48.96%, #7e5d28 100%)"
        : "transparent";
    }};
    &:hover {
      box-shadow: ${({ isActive }) =>
        isActive ? "0px 0px 10px 0px rgba(255, 122, 0, 0.5)" : ""};
    }
  `;
  return (
    <div className="flex flex-col">
      <div className="relative">
        {index === 0 && (
          <div className="absolute right-[-100%] top-[4px] z-1">
            <Line color={activeColor} />
          </div>
        )}
        {isLastItem && (
          <div className="absolute left-[100%] top-[4px] z-1">
            <Line color={activeColor} />
          </div>
        )}
        <div className="z-10 relative">
          <ActiveIcon color={activeColor} />
        </div>
      </div>
      <div className="absolute left-[-150px] md:left-[-200px] 2xl:left-[-240px] top-[33px]">
        <Wrap
          className="relative w-[300px] md:w-[400px] 2xl:w-[500px] h-[220px] z-10"
          isActive={isActive}
        >
          {<WrapCustomBorder isDisabled={index > 2} />}
          <div className="absolute left-1/2 transform -translate-x-1/2 top-0 font-elMesseri flex flex-col items-center justify-center w-full h-full px-6 lg:px-12 gap-3">
            <p className="text-24px text-gradient font-bold ">{title}</p>
            <Image
              src="/icons/union.svg"
              alt="nft-detail-divider"
              width={416}
              height={9}
            />
            <p className="text-gray-25 leading-[28px] text-[14px] lg:text-16px">{description}</p>
          </div>
        </Wrap>
      </div>
    </div>
  );
};
const WrapStep = styled.div<{
  size: number;
}>`
  .ant-steps-item-content {
    margin: 0;
    display: none;
  }
  .ant-steps-item-tail {
    top: 4.4px;
    width: 102%;
    left: -6px;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: linear-gradient(
      180deg,
      #af862c 8.59%,
      #f8f29c 45.7%,
      #d9bd5c 66.66%,
      #ad842a 95.83%
    ) !important;
    background: #af862c;
    height: 2px;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #31323c;
    top: 2px;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #31323c !important;
  }
  @media (max-width: 768px) {
    .ant-steps {
      min-width: ${({ size }) => {
        return `${size * 350}px !important`;
      }};
      margin-left: 120px;
    }
  }
  @media ((min-width: 768px) and (max-width: 1368px)) {
    .ant-steps {
      min-width: ${({ size }) => {
        return `${size * 400}px !important`;
      }};
      margin-left: 150px;
    }
  }
  @media (min-width: 1368px) {
    .ant-steps {
      min-width: ${({ size }) => {
        return `${size * 550}px !important`;
      }};
      margin-left: 200px;
    }
  }
`;
export const Roadmap = ({ roadmaps }: { roadmaps: RoadmapItem[] }) => {
  const minW = {
    xs: roadmaps?.length * 350,
    md: roadmaps?.length * 350,
    "2xl": roadmaps?.length * 550,
  };
  const renderDot = useCallback(
    (dot: any, data: any) => {
      return customDot(dot, data, roadmaps);
    },
    [roadmaps]
  );
  return (
    <WrapStep
      size={roadmaps?.length}
      className={`scroll-container cursor-pointer min-h-300px relative flex scroll-smooth z-10`}
    >
      {!!minW && (
        <Steps
          className={`font-elMesseri relative `}
          current={2}
          progressDot={renderDot}
          items={roadmaps}
          responsive={false}
        />
      )}
    </WrapStep>
  );
};
