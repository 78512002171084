import React from "react";
import Image from "next/image";
import { ILinkDetail } from "views/NFTMarket/types";
export const Overview = ({
  links,
  description,
}: {
  links: ILinkDetail[];
  description: string;
}) => {
  const link = links?.find(link => link?.is_active) ?? null
  return (
    <section className="mt-[25%] pb-20" id="overview">
      <div className="container text-white flex justify-end flex-col h-full gap-[2em] items-center relative">
        <div className="absolute top-[10%] translate-x-1/2  ">
          <Image
            src="/images/shadow.png"
            width={677}
            height={333}
            alt="shadow"
          />
        </div>
        <div className="w-full flex-col flex items-center justify-center mt-10 lg:mt-0">
          <div className="w-[70%] xl:w-full flex justify-center">
            <Image
              src="/images/elowyn.svg"
              width={716}
              height={88}
              alt="elowyn"
            />
          </div>
          <div className="w-[40%] xl:w-full flex justify-center mt-4">
            <Image
              src="/images/quest-of-time.svg"
              width={285}
              height={40}
              alt="play4earn"
            />
          </div>
        </div>

        <h4 className="text-center text-white text-16px max-w-[500px] relative z-10">
          {description}
        </h4>
        <div className="cursor-pointer w-[70%] lg:w-[80%] md:w-[90%] xl:w-full flex justify-center items-center z-10">
          <a
            target={link?.type ==='external' ? "_blank" :'_parent'}
            href={link?.link ?? "https://www.earthwise.global/game-campaign"}
            rel="noopener noreferrer"
          >
            <Image
              src="/images/comming-soon.png"
              width={383}
              height={228}
              alt="comming-soon"
            />
          </a>
        </div>
      </div>
    </section>
  );
};
