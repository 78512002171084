import PrimaryButton from "components/common/button";
import React, { useCallback } from "react";
import Image from "next/image";
import { ArrowRight } from "components/Icon/ArrowRight";
import { EarlyRegisterInfo } from "views/NFTMarket/types";
import { DisplayName } from "components/common/DisplayNameCollection";

export const SpecialPromotion = ({ bonus }: { bonus: EarlyRegisterInfo }) => {
  const { title, description } = bonus ?? {};
  const scrollToSubscribeSection = useCallback(() => {
    window.open(
      "https://www.earthwise.global/game-subscribe",
      "_blank",
      "noopener"
    );
  }, []);
  return (
    <section className=" mt-10 md:mt-40 lg:mt-60 px-4 lg:px-10 3xl:px-0 ">
      <div
        className="container text-white flex h-full py-8 xl:py-15 gap-[2em] items-center justify-between px-8 xl:px-20 flex-col xl:flex-row  min-h-[450px] xl:min-h-[350px] relative"
        style={{
          backgroundImage: "url(/images/mask-bg.png)",
          backgroundSize: "cover",
        }}
      >
        <div className=" text-white text-4xl font-semibold w-full xl:w-2/5 flex flex-col">
          <p className="text-gradient text-40px font-bold">
            <DisplayName name={title} length={50} />
          </p>
          <p className="text-gray-300 text-base mt-2">{description}</p>
          <div className="mt-6">
            <PrimaryButton
              text="Pre-register"
              icon={<ArrowRight />}
              onClick={scrollToSubscribeSection}
            />
          </div>
        </div>
        <div className="">
          <Image
            src="/images/register.png"
            width={720}
            height={260}
            alt="register-banner"
          />
        </div>
      </div>
    </section>
  );
};
